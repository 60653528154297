import React, {Component} from 'react';
import Particles from 'react-particles-js'; 
import ValiLaptop from "../components/valiLaptop"

class ParticlesContainer extends Component {
render() {
    return (
        <div
          style={{
            position: "relative"
        }}
        >
        <Particles 
            params={{
              "particles": {
                "number": {
                  "value": 80,
                  "density": {
                    "enable": true,
                    "value_area": 800
                  }
                },
                "color": {
                  "value": "#ee991c"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#000000"
                  },
                  "polygon": {
                    "nb_sides": 5
                  },
                  "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                  }
                },
                "opacity": {
                  "value": 0.5,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                  }
                },
                "size": {
                  "value": 3,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": true,
                  "distance": 150,
                  "color": "#ee991c",
                  "opacity": 0.4,
                  "width": 1
                },
                "move": {
                  "enable": true,
                  "speed": 1,
                  "direction": "none",
                  "random": false,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "bubble"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "push"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 400,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 155.84415584415586,
                    "size": 19.98001998001998,
                    "duration": 3.4365634365634365,
                    "opacity": 8,
                    "speed": 3
                  },
                  "repulse": {
                    "distance": 87.91208791208791,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
              "retina_detect": true
            }} />
                  <div
                    // style={{
                    //   position: "absolute",
                    //   align: "center",
                    //   // textAlign: "center",
                    //   top: "20%",
                    //   width: "100%",
                    //   height: "10em",
                    // }}
                    className="animation-landing"
                  >
                    <div className="grid-2-col" align="center">
                      <div className="landing-left-vali-info">
                        <ValiLaptop />
                      </div>
                      <div className="landing-right-vali-info">
                        <div className="info-text-title">How does Vali value a property?</div>
                        <div className="info-text">Vali uses our proprietary and purpose-built machine learning algorithms to estimate the value of a property.</div>
                      </div>
                    </div>
                  </div>
            </div>
    )
}
}

export default ParticlesContainer;