import React from "react"

import Layout from "../components/layout"
import ImageHouse from "../components/imageHouse"
import GraphIcon from "../components/graphIcon"
import MagGlass from "../components/magGlass"
import CashIcon from "../components/cashIcon"
import OrangeLogo from "../components/orangeLogo"
import FbLogo from "../components/fbLogo"
import IgLogo from "../components/igLogo"
import SEO from "../components/seo"
import PropertyLookup from "../components/propertyLookup"
import ReviewStars from "../components/reviewStars"
import ParticlesContainer from "../components/app"
import {OutboundLink} from "gatsby-plugin-google-analytics"
import { isFirefox } from "react-device-detect"
import RaizLandingPageRHO from "../components/raizLandingPageRHO"

const firefoxExtension = 'https://addons.mozilla.org/en-US/firefox/addon/vali-property-valuation/';
const chromeExtension = 'https://chrome.google.com/webstore/detail/vali/kjmejadpokndcieffgendnafognhgimj';
const isFF = false;

class IndexPage extends React.Component {

  state = {
    isFF: isFF,
    extensionLink: chromeExtension,
    linkDescription: "Add Vali to Chrome - It's free"
  }

  componentDidMount() {  
    if (isFirefox) {
      this.setState({
        isFF: true,
        extensionLink: firefoxExtension,
        linkDescription: "Add Vali to Firefox - It's free"
      })
    } else {
      this.setState({
        isFF: false,
        extensionLink: chromeExtension,
        linkDescription: "Add Vali to Chrome - It's free"
      })
    }
  }
  
  render () {

    const {isFF, extensionLink, linkDescription} = this.state
 
    return (
      <Layout>
        <SEO title="Home" />
        <section id="landing-page">
          <div className="container">
            <div className="row">
              <div className="grid-2-col landing-page-content">
                <div className="landing-left">
                  <h1>
                    Find out what a property is worth
                  </h1>
                  <PropertyLookup minQueryLength={5} debounceDelay={200} />
                  <p>
                  Vali uses our own machine learning and data analytics to 
                  provide you with free property data and information
                  </p>
                  <OutboundLink className="btn btn-blue btn-block-mobile " href={extensionLink} target="_blank" rel="noreferrer"> {linkDescription}</OutboundLink> 
                  <div className="chrome-review" hidden={isFF}>
                    <ReviewStars /> Chrome Store Review Average
                  </div>
                  <div className="spacing"></div>
                </div>
                <div className="landing-right-image">
                  <ImageHouse />
                </div>
              </div>
              <div className="custom-shape-divider-bottom-1593056775">
                <svg
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1200 120"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                    className="shape-fill"
                  ></path>
                </svg>           
              </div>
            </div>        
          </div>
        </section>
        <section id="grey-page">
          <div className="container">
            <div className="row">
              <div className="grid-1-col" align="center">
                <h2>We're here to help you by democratising property information</h2>
                <h3>Knowledge is power, and we want to put the power in your hands.</h3>
              </div>
              <div className="grey-margin"></div> 
              <div className="grid-3-col" align="center">
                <div className="landing-left-icons">
                    <GraphIcon />
                    <h4>Get useful</h4>
                    <h4>information</h4>
                    <div className="grey-textspacing"></div>
                    <h6>The information you</h6>
                    <h6>need all in one spot</h6>
                </div>
                <div className="landing-middle-icons">
                  <MagGlass />
                  <h4>Explore</h4>
                  <h4>property data</h4>
                  <div className="grey-textspacing"></div>
                  <h6>Essential info for buying,</h6>
                  <h6>selling, leasing and browsing</h6>
                </div>
                <div className="landing-right-icons">
                  <CashIcon />
                  <h4>Completely</h4>
                  <h4>free</h4>
                  <div className="grey-textspacing"></div>
                  <h6>We're here to help</h6>
                  <h6>you, not charge you</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
        <div className="container-animated">
          <ParticlesContainer />
        </div>
        </section>
        <section id="orange-box">
          <div className="container">
            <div className="row">
              <div className="grid-2-col">
                <div className="landing-left">
                  <h2>
                    Get your free report
                  </h2>
                </div>
                <div className="landing-right">
                  <PropertyLookup minQueryLength={5} debounceDelay={200} />
                </div>
              </div>
            </div>
          </div>
        </section> 
        <section id="grey-footer">
        <div className="landing-bottom-ad" align="center">
          <OutboundLink href="https://raizinvest.com.au/raiz-home-ownership/?utm_source=Vali&utm_medium=Banner&utm_campaign=RHOValiAd" target="_blank" rel="noreferrer" align="center"> 
            <RaizLandingPageRHO />
          </OutboundLink>
        </div>
        </section>
        <section id="grey-footer">
          <div className="container">
            <div className="row">
              <div className="grey-margin"></div>
              <div className="grid-2-col">
                <div className="landing-left-logo-bottom">
                  <OrangeLogo />
                </div>
                <div className="landing-right-social-media">
                  <OutboundLink href="https://www.facebook.com/Vali-104953721328768" target="_blank" rel="noreferrer">
                    <FbLogo />
                  </OutboundLink>
                  <OutboundLink href="https://www.instagram.com/vali.com.au/" target="_blank" rel="noreferrer">
                    <IgLogo />
                  </OutboundLink>
                </div>
              </div>
              <div className="grid-1-col">
                <div className="grey-textspacing"></div>
                <div className="grey-textspacing"></div>
                <h6>Vali makes no representation nor understanding, implicitly or explicitly
                  and accepts no responsibility as to the accuracy, reliability or completeness
                  of the property information. Property price predictions are not valuations and 
                  should not be treated as anything more than an estimate.
                </h6>
              </div>
            </div>
          </div>
        </section>  
      </Layout>
    )    
  }
}

export default IndexPage